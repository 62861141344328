<template>
  <div class="letter_detail_page">
    <div class="letter_detail-nav">
      <p>
        <span @click="goBack">返回</span>
      </p>
    </div>
    <div class="letter_detail_cont">
      <div class="content">
          <div class="title">{{info.messageTitle}}</div>
          <div class="time">时间：{{common.dateFormat(info.createtime * 1000)}}</div>
          <pre v-html="info.messageContent"></pre>
          <!-- <div v-html=""></div> -->
      </div>
    </div>
    
  </div>
</template>

<script>
  export default {
    name: 'letterDetail',
    components: {},
    data() {
      return {
          info: {}
      }
    },
    created() {},
    mounted() {
        this.api.admin.messageInfo({messageId:this.$route.query.messageId}).then(res=>{
            this.info = res.data
        })
    },
    methods: {
      //退出
      goBack(){
        this.$router.go(-1);
      }
    },
  }
</script>

<style lang="scss" scoped>
  .letter_detail_page {
    background:#F1F1F1;
    .letter_detail-nav{
      height: 68px;
      line-height:68px;
      background: #BA3037;
      
      >p{
        margin:0 auto;
        width:1434px;
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        @include flex(row,space-between,center);
        >span:nth-child(1){
          cursor:pointer;
          background:url('../../assets/img/rank/Icon_Ph_back.png') no-repeat left center;
          background-size:9px 15px;
          padding-left:17px;
        }
      }
    }
    .letter_detail_cont{
      width:1354px;
      background:#FFFFFF;
      margin:0 auto;
      padding:30px 40px 120px 40px;
      background:#FFFFFF;
    }
    .content{
        box-sizing: border-box;
        // padding: 0 40px;
        min-height: 543px;
        .title {
          // margin-top:40px;
          margin-bottom: 10px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          
        }
    
        .time {
          margin-top:14px;
          margin-bottom:20px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
    }
  }
</style>
<style lang="scss">